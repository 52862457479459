import { MenuEntry } from '@fbomb-finance/uikit'

const config: MenuEntry[] = [
  {
    label: "Home",
    icon: "HomeIcon",
    href: "https://app.fbomb.finance/",
  },
  {
    label: "Trade",
    icon: "TradeIcon",
    items: [
      {
        label: "Exchange",
        href: "/",
      },
      {
        label: "Liquidity",
        href: "/pool",
      },
    ],
  },
  {
    label: "Bombing Range",
    icon: "FarmIcon",
    href: "https://app.fbomb.finance/farms",
  },
  {
    label: "The Workshop",
    icon: "WorkshopIcon",
    href: "https://app.fbomb.finance/staking"
  },
  {
    label: "Minutes to Midnight",
    icon: 'MtmIcon',
    href: "https://app.fbomb.finance/mtm",
    isNew: true,
  },
  {
    label: 'Partners',
    icon: 'PartnershipIcon',
    href: 'https://app.fbomb.finance/partners',
  },
  {
    label: 'Info',
    icon: 'InfoIcon',
    href: 'https://info.fbomb.finance'
  },
  {
    label: "More",
    icon: "MoreIcon",
    items: [
      {
        label: "Voting",
        href: "https://vote.fbomb.finance",
      },
      {
        label: "Github",
        href: "https://github.com/fbomb-finance",
      },
      {
        label: "Docs",
        href: "https://docs.fbomb.finance",
      },
    ],
  },
];

export default config
