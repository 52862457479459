import { useCurrency } from 'hooks/Tokens'
import { useTradeExactIn } from 'hooks/Trades'
import { tryParseAmount } from 'state/swap/hooks'

const useGetBombUsdcLpPrice = () => {
  const shrapAddress = '0x8503eb4A136bDBeB323E37Aa6e0FA0C772228378'
  const usdcAddress = '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75'
  const inputCurrency = useCurrency(shrapAddress)
  const outputCurrency = useCurrency(usdcAddress)
  const parsedAmount = tryParseAmount('1', inputCurrency ?? undefined)
  const bestTradeExactIn = useTradeExactIn(parsedAmount, outputCurrency ?? undefined)
  const price = bestTradeExactIn?.executionPrice.toSignificant(6)
  return price ? parseFloat(price) : undefined
}

export default useGetBombUsdcLpPrice
