import { useEffect } from 'react'
import useGetBombUsdcLpPrice from 'utils/useGetBombUsdcLpPrice'

const useGetDocumentTitlePrice = () => {
  const shrapPriceUsdc = useGetBombUsdcLpPrice()

  const shrapPriceUsdcString =
    Number.isNaN(shrapPriceUsdc) || shrapPriceUsdc === 0 || !shrapPriceUsdc
      ? ''
      : ` - $${shrapPriceUsdc.toLocaleString(undefined, {
          minimumFractionDigits: 3,
          maximumFractionDigits: 3,
        })}`

  useEffect(() => {
    document.title = `BombSwap${shrapPriceUsdcString}`
  }, [shrapPriceUsdcString])
}
export default useGetDocumentTitlePrice
