import React from 'react'
import { Menu as UikitMenu } from '@fbomb-finance/uikit'
import { useWeb3React } from '@web3-react/core'

import useTheme from 'hooks/useTheme'
import useGetLocalProfile from 'hooks/useGetLocalProfile'
import useAuth from 'hooks/useAuth'
import useGetBombUsdcLpPrice from 'utils/useGetBombUsdcLpPrice'
import links from './config'

const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { isDark, toggleTheme } = useTheme()
  const bombPriceUsdc = useGetBombUsdcLpPrice()
  const profile = useGetLocalProfile()

  return (
    <UikitMenu
      links={links}
      account={account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      bombPriceUsd={bombPriceUsdc}
      profile={profile}
      {...props}
    />
  )
}

export default Menu
